<template>
    <div class="bidding-wrapper">
        <!-- Item Name/Price -->
        <div class="time-remaining-wrapper">
            <span class="time-icon">
                <i class="fa fa-clock-o"></i>
                Time Left
            </span>
            <div class="time-remaining">
                {{ newTimeRemaining }}
            </div>
        </div>
        <div
            class="item-title"
            :style="{
                'font-family': org_skin && org_skin.h1_font ? org_skin.h1_font.font_family : '',
            }"
        >
            {{ auctionItem.item_title }}
        </div>

        <button type="button" class="btn btn-blue btn-block" onclick="location.reload();">Refresh</button>

        <div v-bind:class="'loadable ' + (loading ? 'is-loading' : '')">
            <div class="item-value" v-if="auctionItem.value > 0">Value: ${{ auctionItem.value }}</div>
            <div
                class="bid-status-badge winning"
                v-if="auctionItem.myHighBid"
                v-bind:class="auctionItem.userIsHighBidder ? 'winning' : 'outbid'"
            >
                {{ auctionItem.userIsHighBidder ? 'You’re winning' : 'You’ve been outbid' }}
            </div>
            <div class="current-bid">
                <div class="current-bid-label">Current Winning Bid</div>
                <div class="current-bid-amount">
                    <span class="bid-refresh-status" v-bind:class="websocketsOnline ? 'online' : ''"></span>
                    ${{ auctionItem.highBid ? auctionItem.highBid.bid_amount : 0.0 }}
                </div>
            </div>
            <div
                v-if="auctionItem.myHighBid"
                class="my-current-bid"
                v-bind:class="auctionItem.userIsHighBidder ? 'high-bidder' : 'outbid'"
            >
                <div class="current-bid-label">My Bid</div>
                <div class="current-bid-amount">
                    ${{ auctionItem.myHighBid ? auctionItem.myHighBid.bid_amount : 0.0 }}
                </div>
            </div>

            <!-- Enter Bid -->
            <div v-if="auctionItem.hasEnded" class="enter-bid-label">This auction has ended</div>
            <div v-else>
                <div v-if="!auctionItem.auction.userHasCardOnFile">
                    <p class="about-the-item">
                        PLEASE NOTE: Please add your payment method before bidding or purchasing raffle tickets! Thank
                        you for your support!
                    </p>
                    <a
                        class="btn btn-blue btn-stretch"
                        v-bind:href="'/auction/mobile-app/enter-card-information/' + auctionItem.auction.id"
                        role="button"
                        >BID / ADD PAYMENT METHOD</a
                    >
                </div>
                <div v-else>
                    <div
                        class="enter-bid-label"
                        :style="{
                            'font-family': org_skin && org_skin.h1_font ? org_skin.h1_font.font_family : '',
                        }"
                    >
                        Enter Your Bid Amount
                    </div>
                    <div class="input-group" id="place-bid-input-group">
                        <span class="input-group-btn">
                            <button class="btn" id="decrease-bid" type="button" @click="decreaseBid">-</button>
                        </span>
                        <input type="hidden" id="item-id" name="item-id" v-bind:value="auctionItem.id" />
                        <input
                            class="form-control"
                            id="bid-amount"
                            name="bid-amount"
                            v-bind:value="auctionItem.minimumBidAmount"
                            data-type="number"
                            data-placeholder="$0.00"
                            v-bind:data-step="auctionItem.bidIncrementAmount"
                            v-bind:data-min="auctionItem.minimumBidAmount"
                        />
                        <span class="input-group-btn">
                            <button class="btn" id="increase-bid" type="button" @click="increaseBid">+</button>
                        </span>
                    </div>
                    <p class="bid-info">In this auction, the amount you bid is exactly what you'll pay if you win.</p>
                    <button
                        class="btn btn-blue btn-stretch pwi-confirm-button"
                        id="place-bid"
                        data-endpoint="/auction/mobile-app/place-bid"
                        data-callback="placeAuctionItemBid"
                        data-callback-arg="button#place-bid"
                    >
                        {{ auctionItem.buttonText }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Cleave from 'cleave.js';

export default {
    name: 'ItemBidComponent',
    props: {
        org_skin: {
            type: Object,
            default: {},
        },
    },
    mounted() {
        this.startCountdown();
    },
    created() {
        this.getAuctionItem();
        this.initializeWebSocketListener();
    },
    data() {
        return {
            loading: true,
            websocketsOnline: true,
            BID_AMOUNT_INPUT: null,
            newTimeRemaining: '',
            auctionItem: {
                id: '',
                item_type: '',
                auction_id: '',
                auction_category_id: '',
                sponsor_id: '',
                item_title: '',
                item_detail: '',
                value: '',
                min_bid_amount: '',
                bid_increment: '',
                max_bid_amount: '',
                max_bid_user_id: '',
                views: '',
                pickup_details: '',
                contact_details: '',
                default_auction_item_image_id: '',
                item_end_datetime_utc: '',
                time_to_notify_users_of_end: '',
                item_sold: '',
                created_at: '',
                updated_at: '',
                highBid: {
                    auction_item_id: '',
                    user_id: '',
                    bid_amount: '',
                    created_at: '',
                    utc_timestamp: '',
                    notified: '',
                },
                myHighBid: {
                    auction_item_id: '',
                    user_id: '',
                    bid_amount: '',
                    created_at: '',
                    utc_timestamp: '',
                    notified: '',
                },
                auction: {
                    id: '',
                    auction_key: '',
                    org_id: '',
                    auction_name: '',
                    file_id: '',
                    auction_status: '',
                    overview: '',
                    contact_email: '',
                    receive_payments: '',
                    process_payments: '',
                    views: '',
                    auction_end_datetime_utc: '',
                    time_to_notify_users_of_end: '',
                    timezone_id: '',
                    auction_sponsor_id: '',
                    auto_bidding: '',
                    utilize_max_bid: '',
                    bid_increment: '',
                    created_at: '',
                    updated_at: '',
                    userHasCardOnFile: false,
                },
                userIsHighBidder: false,
                hasEnded: false,
                minimumBidAmount: '',
                bidIncrementAmount: '',
                buttonText: '',
            },
        };
    },
    methods: {
        startCountdown() {
            const countdownInterval = setInterval(() => {
                if(!this.auctionItem.item_end_datetime_utc) {
                  return ''
                }

                let endDate = this.auctionItem.item_end_datetime_utc;
                let itemEndDateTime = new Date(endDate + 'Z');
                let now = new Date();
                let timeDifference = itemEndDateTime.getTime() - now.getTime();

                if (timeDifference < 0) {
                    return 'This auction has ended.';
                }

                let seconds = Math.floor((timeDifference / 1000) % 60);
                let minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
                let hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
                let days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

                this.newTimeRemaining =
                    days > 0 ? `${days}d ${hours}h ${minutes}m` : `${hours}h ${minutes}m ${seconds}s`;
            }, 1000);
        },
        decreaseBid() {
            let $bidAmountInput = $('input#bid-amount');
            let step = parseFloat($bidAmountInput.attr('data-step')) || 1.0;

            this.updateBid($bidAmountInput, step * -1);
        },
        getAuctionId() {
            return parseInt($('input#auction-id').val());
        },
        getAuctionItemId() {
            return parseInt($('input#auction-item-id').val());
        },
        getAuctionItem() {
            axios.get('/api/auction/get-auction-item/' + this.getAuctionItemId()).then(response => {
                if (response.data.data) {
                    this.auctionItem = response.data.data;
                }
                this.loading = false; // FIXME: This timeout is a hack to beat the race condition
                setTimeout(this.initializeBidAmountInput, 500);
            });
        },
        increaseBid() {
            let $bidAmountInput = $('input#bid-amount');
            let step = parseFloat($bidAmountInput.attr('data-step')) || 1.0;

            this.updateBid($bidAmountInput, step);
        },
        initializeBidAmountInput() {
            console.log('initializeBidAmountInput');
            if (this.shouldInitializeBidAmountInput()) {
                try {
                    this.BID_AMOUNT_INPUT.destroy();
                } catch (exception) {
                    // This is fine. It just means that the input element has not been initialized yet.
                }

                let bidAmount = parseFloat($('input#bid-amount').val() || 0).toFixed(2);
                this.BID_AMOUNT_INPUT = new Cleave('input#bid-amount', {
                    numeral: true,
                    prefix: '$',
                    rawValueTrimPrefix: true, // Note: Removing this will break the +/- buttons
                });
                console.log('this.BID_AMOUNT_INPUT: ', this.BID_AMOUNT_INPUT);
                $('input#bid-amount').val(bidAmount);
                this.BID_AMOUNT_INPUT.setRawValue(bidAmount);
            }
        },
        initializeWebSocketListener() {
            let auctionChannel = 'auction.' + this.getAuctionId();
            console.log('auctionChannel: ', auctionChannel);
            Echo.channel(auctionChannel).listen('AuctionBidPlaced', event => {
                console.log('AuctionBidPlaced:', event);
                let itemId = event.bid.item.id;
                console.log('itemId: ', itemId);
                console.log('this.getAuctionItemId(): ', this.getAuctionItemId());
                if (itemId === this.getAuctionItemId()) {
                    this.getAuctionItem();
                }
            });

            Echo.connector.socket.on('connect', () => {
                this.websocketsOnline = true;
            });
            Echo.connector.socket.on('disconnect', () => {
                this.websocketsOnline = false;
            });

            if (!Echo.connector.socket.connected) {
                this.websocketsOnline = false;
            }
        },
        shouldInitializeBidAmountInput() {
            return $('input#bid-amount').length;
        },
        updateBid($bidAmountInput, changeAmount) {
            let minBid = parseFloat($bidAmountInput.attr('data-min')) || 1.0;
            let bidAmount = parseFloat(this.BID_AMOUNT_INPUT.getRawValue()) || minBid;
            let maxBid = parseFloat($bidAmountInput.attr('data-max')) || 999999.99;
            changeAmount = parseFloat(changeAmount);

            // Format the values
            minBid.toFixed(2);
            bidAmount.toFixed(2);
            maxBid.toFixed(2);
            changeAmount.toFixed(2);

            // Decrement the bid amount
            (bidAmount += changeAmount).toFixed(2);

            // Validate
            if (bidAmount < minBid) {
                bidAmount = minBid;
            } else if (bidAmount > maxBid) {
                bidAmount = maxBid;
            }

            // Format the value as money
            bidAmount = bidAmount.toFixed(2);

            // Set the new bid amount
            $bidAmountInput.val(bidAmount);
            this.BID_AMOUNT_INPUT.setRawValue(bidAmount);
        },
    },
};
</script>
