<template>
    <div class="raffle-list" v-bind:class="'loadable ' + (loading ? 'is-loading' : '')">
        <div v-for="raffle in raffles">
            <div class="raffle">
                <img v-bind:src="raffle.raffleTicketImage"
                     v-bind:alt="raffle.raffle_title"
                     class="raffle-ticket-background">
                <div class="raffle-ticket-text">
                    <div class="raffle-ticket-left">
                        <div class="raffle-title">{{ raffle.raffle_title }}</div>
                        <div class="raffle-ticket-button">
                            <a v-bind:href="'/auction/mobile-app/raffle/' + raffle.id"
                               class="btn btn-blue buy-raffle">
                                <span v-if="raffle.soldOut">SOLD OUT</span>
                                <span v-else>View Raffle</span>
                            </a>
                        </div>
                    </div>
                    <div class="raffle-ticket-right">
                        <div class="raffle-ticket-price">${{ raffle.ticket_amount }}</div>
                        <div class="per-ticket">Per Ticket</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        name: 'RaffleListComponent',
        created() {
            this.getRaffleList();
            this.initializeWebSocketListener();
        },
        mounted() {
            //
        },
        data() {
            return {
                loading: true,
                raffles: [],
                raffle: {
                    id: '',
                    auction_id: '',
                    sponsor_id: '',
                    raffle_title: '',
                    raffle_description: '',
                    raffle_end_datetime_utc: '',
                    time_to_notify_users_of_end: '',
                    default_auction_raffle_image_id: '',
                    ticket_amount: '',
                    value_amount: '',
                    max_ticket_qty_total: '',
                    max_ticket_qty_user: '',
                    views: '',
                    pickup_details: '',
                    contact_details: '',
                    rules: '',
                    created_at: '',
                    updated_at: '',
                    auction: {
                        id: '',
                        auction_key: '',
                        org_id: '',
                        auction_name: '',
                        file_id: '',
                        auction_status: '',
                        overview: '',
                        contact_email: '',
                        receive_payments: '',
                        process_payments: '',
                        views: '',
                        auction_end_datetime_utc: '',
                        time_to_notify_users_of_end: '',
                        timezone_id: '',
                        auction_sponsor_id: '',
                        auto_bidding: '',
                        utilize_max_bid: '',
                        bid_increment: '',
                        created_at: '',
                        updated_at: '',
                        userHasCardOnFile: false,
                    },
                    hasEnded: false,
                    soldOut: false,
                    myTicketCount: 0,
                    raffleTicketImage: '',
                }
            };
        },
        methods: {
            getAuctionId() {
                return parseInt($('input#auction-id').val());
            },
            getRaffleList() {
                console.log('getRaffleList');
                axios.get('/api/auction/get-raffle-list/' + this.getAuctionId())
                    .then((response) => {
                        console.log('response: ', response.data.data);
                        this.raffles = response.data.data;
                        this.loading = false;
                    });
            },
            initializeWebSocketListener() {
                Echo.channel('auction.' + this.getAuctionId())
                    .listen('RaffleTicketCreated', (event) => {
                        console.log('RaffleTicketCreated:', event);
                        this.getRaffleList();
                    })
                    .listen('RaffleTickedUpdated', (event) => {
                        console.log('RaffleTickedUpdated:', event);
                        this.getRaffleList();
                    });
            },
        }
    };
</script>