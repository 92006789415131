<template>
    <div>
        <div class="row">
            <div class="col-xs-6">
                <select
                    name="sort"
                    id="sort"
                    v-model="filters.sort"
                    @change="changeSort"
                    :style="{
                        color: org_skin ? org_skin.primary_hex : '',
                        'border-color': org_skin ? org_skin.primary_hex : '',
                    }"
                >
                    <option value="" selected>Sort by</option>
                    <option value="category">Category</option>
                    <option value="title_az">Title (A-Z)</option>
                    <option value="title_za">Title (Z-A)</option>
                    <option value="price_low_high">Price (ASC)</option>
                    <option value="price_high_low">Price (DESC)</option>
                </select>
            </div>
            <div class="col-xs-6">
                <button
                    type="button"
                    class="btn btn-blue btn-block"
                    @click="getAuctionItemList"
                    :style="{
                        'border-color': org_skin ? org_skin.secondary_hex : '',
                        'background-color': org_skin ? org_skin.secondary_hex : '',
                    }"
                >
                    Refresh
                </button>
            </div>
        </div>
        <div class="auction-item-groups" v-bind:class="'loadable ' + (loading ? 'is-loading' : '')">
            <div v-if="auctionItems.length" class="row">
                <div
                    v-for="auctionItem in auctionItems"
                    :key="auctionItem.id"
                    class="col-lg-3 col-md-4 col-sm-6 col-xs-12"
                >
                    <div
                        class="auction-item"
                        v-bind:data-price="auctionItem.displayPrice"
                        v-bind:data-has-bids="auctionItem.bids.length ? '1' : '0'"
                        v-bind:data-category-id="auctionItem.auction_category_id"
                        v-bind:data-id="auctionItem.id"
                    >
                        <a v-bind:href="'/auction/mobile-app/auction-item/' + auctionItem.id" class="banner-image">
                            <img
                                class="c-lazy"
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                v-bind:data-src="
                                    auctionItem.itemImages &&
                                    auctionItem.itemImages.cdn_id
                                        ? cloudinary_video_helper + auctionItem.itemImages.cdn_id + '.jpg'
                                        : ''
                                "
                                v-bind:alt="auctionItem.item_title"
                                v-if="
                                    auctionItem.itemImages &&
                                    auctionItem.itemImages.file_path
                                        ? auctionItem.itemImages.file_path.includes('.mov') ||
                                          auctionItem.itemImages.file_path.includes('.mp4')
                                        : false
                                "
                            />
                            <img
                                class="c-lazy"
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                v-bind:data-src="
                                    auctionItem.itemImages &&
                                    auctionItem.itemImages.file_path
                                        ? resizeImg(auctionItem.itemImages.file_path)
                                        : ''
                                "
                                v-bind:alt="auctionItem.item_title"
                                v-else
                            />
                            <div class="time-remaining-wrapper">
                                <span class="time-icon">
                                    <i class="fa fa-clock-o"></i>
                                    Time Left
                                </span>
                                <div class="time-remaining">
                                    {{ auctionItem.timeRemaining }}
                                </div>
                            </div>
                        </a>
                        <div class="item-title-row">
                            <div class="item-title">{{ auctionItem.item_title }}</div>
                            <div class="item-current-bid">${{ auctionItem.displayPrice }}</div>
                        </div>
                        <div class="bid-status-row">
                            <div v-if="auctionItem.userBidStatus === 'NO_WINNER'" class="my-bid-status">
                                This auction has ended
                            </div>
                            <div v-else-if="auctionItem.userBidStatus === 'WINNING'" class="my-bid-status">
                                You’re Winning!
                            </div>
                            <div v-else-if="auctionItem.userBidStatus === 'WON'" class="my-bid-status">
                                You’ve Won!
                            </div>
                            <div v-else-if="auctionItem.userBidStatus === 'OUTBID'" class="my-bid-status">
                                You’ve been outbid
                            </div>
                            <div v-else-if="auctionItem.userBidStatus === 'LOST'" class="my-bid-status">
                                You did not win
                            </div>
                            <div v-else-if="auctionItem.userBidStatus === 'SOLD'" class="my-bid-status">
                                This Item has been sold
                            </div>
                            <div v-else class="my-bid-status"></div>
                            <div
                                v-if="auctionItem.isBidItem"
                                class="bid-count"
                                :style="{
                                    color: org_skin ? org_skin.primary_hex : '',
                                }"
                            >
                                Bids: {{ auctionItem.bids.length }}
                            </div>
                        </div>
                        <div class="bid-now-wrapper">
                            <a
                                v-bind:href="'/auction/mobile-app/auction-item/' + auctionItem.id"
                                class="btn btn-blue bid-now dynamic-button"
                                :style="{
                                    'border-color': org_skin ? org_skin.primary_hex : '',
                                    'background-color': org_skin ? org_skin.primary_hex : '',
                                }"
                            >
                                {{ auctionItem.buttonText }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="no-auction-items">This Auction has no items</div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import cLazy from '~pwi-modules/cloudinary-lazy-load';

const clazy = new cLazy();

export default {
    name: 'ItemListComponent.vue',
    props: {
        org_skin: {
            type: Object,
            default: {},
        },
    },
    mounted() {
        this.checkSort();
    },
    created() {
        this.getAuctionItemList();
        this.initializeWebSocketListener();
    },
    data() {
        return {
            cloudinary_image_helper:
                'https://res.cloudinary.com/project-world-impact/image/upload/w_545,h_300,c_pad,b_auto/',
            cloudinary_video_helper:
                'https://res.cloudinary.com/project-world-impact/video/upload/w_450,h_150,c_lpad,b_auto,f_auto,fl_lossy,q_auto/',
            filters: {
                sort: '',
            },
            auctionItems: [],
            loading: true,
            bid: {
                auction_item_id: '',
                user_id: '',
                bid_amount: '',
                created_at: '',
                utc_timestamp: '',
                notified: '',
            },
            itemImage: {
                id: '',
                auction_item_id: '',
                order: '',
                image_url: '',
                file_id: '',
                created_at: '',
                file_path: '',
            },
            auctionItem: {
                id: '',
                item_type: '',
                auction_id: '',
                auction_category_id: '',
                sponsor_id: '',
                item_title: '',
                item_detail: '',
                value: '',
                min_bid_amount: '',
                bid_increment: '',
                max_bid_amount: '',
                max_bid_user_id: '',
                views: '',
                pickup_details: '',
                contact_details: '',
                default_auction_item_image_id: '',
                item_end_datetime_utc: '',
                time_to_notify_users_of_end: '',
                item_sold: '',
                created_at: '',
                updated_at: '',
                highBid: {
                    auction_item_id: '',
                    user_id: '',
                    bid_amount: '',
                    created_at: '',
                    utc_timestamp: '',
                    notified: '',
                },
                myHighBid: {
                    auction_item_id: '',
                    user_id: '',
                    bid_amount: '',
                    created_at: '',
                    utc_timestamp: '',
                    notified: '',
                },
                bids: [],
                auction: {
                    id: '',
                    auction_key: '',
                    org_id: '',
                    auction_name: '',
                    file_id: '',
                    auction_status: '',
                    overview: '',
                    contact_email: '',
                    receive_payments: '',
                    process_payments: '',
                    views: '',
                    auction_end_datetime_utc: '',
                    time_to_notify_users_of_end: '',
                    timezone_id: '',
                    auction_sponsor_id: '',
                    auto_bidding: '',
                    utilize_max_bid: '',
                    bid_increment: '',
                    created_at: '',
                    updated_at: '',
                    userHasCardOnFile: false,
                },
                itemImages: [],
                userIsHighBidder: false,
                hasEnded: false,
                minimumBidAmount: '',
                bidIncrementAmount: '',
                buttonText: '',
                displayPrice: '',
                userBidStatus: '',
                isBidItem: '',
            },
        };
    },
    methods: {
        startCountdown() {
            this.auctionItems.forEach((item) => {
                // Initialize the timeRemaining
                this.$set(item, 'timeRemaining', this.calculateTimeRemaining(item.item_end_datetime_utc));

                // Set an interval to update timeRemaining every second
                const countdownInterval = setInterval(() => {
                    const newTimeRemaining = this.calculateTimeRemaining(item.item_end_datetime_utc);
                    this.$set(item, 'timeRemaining', newTimeRemaining);

                    // Clear interval if the auction has ended
                    if (newTimeRemaining === 'This auction has ended.') {
                        clearInterval(countdownInterval);
                    }
                }, 1000); // Update every second
            });
        },
        calculateTimeRemaining(endDate) {
            let itemEndDateTime = new Date(endDate + 'Z');
            let now = new Date();
            let timeDifference = itemEndDateTime.getTime() - now.getTime();

            if (timeDifference < 0) {
                return 'This auction has ended.';
            }

            let seconds = Math.floor((timeDifference / 1000) % 60);
            let minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
            let hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
            let days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

            return days > 0
                ? `${days}d ${hours}h ${minutes}m`
                : `${hours}h ${minutes}m ${seconds}s`;
        },
        checkSort() {
            if (localStorage.getItem('auctionSortData') != null) {
                let sortData = JSON.parse(localStorage.getItem('auctionSortData'));
                if (this.getAuctionId()) {
                    if (sortData.hasOwnProperty(this.getAuctionId())) {
                        this.filters.sort = sortData[this.getAuctionId()];
                    }
                }
            }
        },
        changeSort() {
            if (this.filters.sort && this.getAuctionId()) {
                let sortData = {};
                if (localStorage.getItem('auctionSortData') != null) {
                    sortData = JSON.parse(localStorage.getItem('auctionSortData'));
                    sortData[this.getAuctionId()] = this.filters.sort;
                }
                localStorage.setItem('auctionSortData', JSON.stringify(sortData));
            }
            this.getAuctionItemList();
        },
        resizeImg(filePath) {
            // const sizeParam = '/w_545,h_300,c_pad,b_auto/';
            // const output = filePath.substring(0, 60) + sizeParam + filePath.substring(61);
            let urlArr = filePath.split('/');
            let combineUrl = '';
            for (let i = 0; i < urlArr.length; i++) {
                if (!urlArr[i].includes('w_') && !urlArr[i].includes('h_')) {
                    combineUrl += urlArr[i] + '/';
                }
            }
            return combineUrl.slice(0, -1);
        },
        getAuctionId() {
            return parseInt($('input#auction-id').val());
        },
        getAuctionItemList() {
            axios
                .get('/api/auction/get-auction-item-list/' + this.getAuctionId(), {
                    params: {
                        filters: this.filters,
                    },
                })
                .then(response => {
                    if (response.data.data) {
                        this.auctionItems = response.data.data;
                    }
                    this.loading = false;

                    // FIXME: This timeout is a hack to beat the race condition
                    setTimeout(this.lazyLoadCloudinaryImages, 500);
                    this.startCountdown();
                });
        },
        initializeWebSocketListener() {
            Echo.channel('auction.' + this.getAuctionId())
                .listen('AuctionBidPlaced', event => {
                    this.getAuctionItemList();
                })
                .listen('AuctionItemCreated', event => {
                    this.getAuctionItemList();
                })
                .listen('AuctionItemUpdated', event => {
                    this.getAuctionItemList();
                });
        },
        lazyLoadCloudinaryImages() {
            clazy.loadAllImages({
                qualityMultiplier: 2,
            });
        },
    },
};
</script>
