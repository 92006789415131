<template>
    <div v-bind:class="'loadable ' + (loading ? 'is-loading' : '')">
        <div v-if="raffle.myTicketCount" class="my-ticket-count">Purchased: {{ raffle.myTicketCount }}</div>
        <div v-if="raffle.hasEnded" class="raffle-ended">
            This raffle has ended.
        </div>
        <div v-else>
            <div v-if="raffle.soldOut" class="sold-out">SOLD OUT</div>
            <div v-else>
                <div v-if="!raffle.auction.userHasCardOnFile">
                    <p class="about-the-item">
                        PLEASE NOTE: Please add your payment method before bidding or purchasing raffle tickets! Thank
                        you for your support!
                    </p>
                    <a
                        class="btn btn-blue btn-stretch"
                        v-bind:href="'/auction/mobile-app/enter-card-information/' + raffle.auction.id"
                        role="button"
                        >BUY / ADD PAYMENT METHOD</a
                    >
                </div>
                <div v-else>
                    <!-- Buy Raffle Ticket -->
                    <div class="input-group" style="margin-bottom: 20px;">
                        <span class="input-group-addon" id="raffle-ticket-quantity-label">QTY</span>
                        <input
                            type="number"
                            id="raffle-ticket-quantity"
                            name="raffle-ticket-quantity"
                            class="form-control"
                            placeholder="QTY"
                            min="1"
                            step="1"
                            value="1"
                            aria-describedby="raffle-ticket-quantity-label"
                        />
                    </div>

                    <button
                        id="purchase-raffle-ticket"
                        class="btn btn-blue btn-stretch pwi-confirm-button"
                        data-callback="purchaseRaffleTicket"
                        data-callback-arg="button#purchase-raffle-ticket"
                    >
                        Buy Ticket - ${{ raffle.ticket_amount }} EACH
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Cleave from 'cleave.js';

export default {
    name: 'RafflePurchaseComponent',
    mounted() {
        //
    },
    created() {
        this.getRaffleItem();
        this.initializeWebSocketListener();
    },
    data() {
        return {
            loading: true,
            raffle: {
                id: '',
                auction_id: '',
                sponsor_id: '',
                raffle_title: '',
                raffle_description: '',
                raffle_end_datetime_utc: '',
                time_to_notify_users_of_end: '',
                default_auction_raffle_image_id: '',
                ticket_amount: '',
                value_amount: '',
                max_ticket_qty_total: '',
                max_ticket_qty_user: '',
                views: '',
                pickup_details: '',
                contact_details: '',
                rules: '',
                created_at: '',
                updated_at: '',
                auction: {
                    id: '',
                    auction_key: '',
                    org_id: '',
                    auction_name: '',
                    file_id: '',
                    auction_status: '',
                    overview: '',
                    contact_email: '',
                    receive_payments: '',
                    process_payments: '',
                    views: '',
                    auction_end_datetime_utc: '',
                    time_to_notify_users_of_end: '',
                    timezone_id: '',
                    auction_sponsor_id: '',
                    auto_bidding: '',
                    utilize_max_bid: '',
                    bid_increment: '',
                    created_at: '',
                    updated_at: '',
                    userHasCardOnFile: false,
                },
                hasEnded: false,
                soldOut: false,
                myTicketCount: 0,
            },
        };
    },
    methods: {
        getAuctionId() {
            return parseInt($('input#auction-id').val());
        },
        getRaffleItem() {
            axios.get('/api/auction/get-raffle-item/' + this.getRaffleItemId()).then(response => {
                console.log('response: ', response.data.data);
                this.raffle = response.data.data;
                this.loading = false;
            });
        },
        getRaffleItemId() {
            return parseInt($('input#raffle-id').val());
        },
        initializeWebSocketListener() {
            let auctionChannel = 'auction.' + this.getAuctionId();
            console.log('auctionChannel: ', auctionChannel);
            Echo.channel(auctionChannel).listen('AuctionRafflePurchased', event => {
                console.log('AuctionRafflePurchased:', event);
                let raffleId = event.rafflePurchase.raffle.id;
                if (raffleId === this.getRaffleItemId()) {
                    this.getRaffleItem();
                }
            });
        },
    },
};
</script>
