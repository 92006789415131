var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-6"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.filters.sort),expression:"filters.sort"}],style:({
                    color: _vm.org_skin ? _vm.org_skin.primary_hex : '',
                    'border-color': _vm.org_skin ? _vm.org_skin.primary_hex : '',
                }),attrs:{"name":"sort","id":"sort"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.filters, "sort", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.changeSort]}},[_c('option',{attrs:{"value":"","selected":""}},[_vm._v("Sort by")]),_vm._v(" "),_c('option',{attrs:{"value":"category"}},[_vm._v("Category")]),_vm._v(" "),_c('option',{attrs:{"value":"title_az"}},[_vm._v("Title (A-Z)")]),_vm._v(" "),_c('option',{attrs:{"value":"title_za"}},[_vm._v("Title (Z-A)")]),_vm._v(" "),_c('option',{attrs:{"value":"price_low_high"}},[_vm._v("Price (ASC)")]),_vm._v(" "),_c('option',{attrs:{"value":"price_high_low"}},[_vm._v("Price (DESC)")])])]),_vm._v(" "),_c('div',{staticClass:"col-xs-6"},[_c('button',{staticClass:"btn btn-blue btn-block",style:({
                    'border-color': _vm.org_skin ? _vm.org_skin.secondary_hex : '',
                    'background-color': _vm.org_skin ? _vm.org_skin.secondary_hex : '',
                }),attrs:{"type":"button"},on:{"click":_vm.getAuctionItemList}},[_vm._v("\n                Refresh\n            ")])])]),_vm._v(" "),_c('div',{staticClass:"auction-item-groups",class:'loadable ' + (_vm.loading ? 'is-loading' : '')},[(_vm.auctionItems.length)?_c('div',{staticClass:"row"},_vm._l((_vm.auctionItems),function(auctionItem){return _c('div',{key:auctionItem.id,staticClass:"col-lg-3 col-md-4 col-sm-6 col-xs-12"},[_c('div',{staticClass:"auction-item",attrs:{"data-price":auctionItem.displayPrice,"data-has-bids":auctionItem.bids.length ? '1' : '0',"data-category-id":auctionItem.auction_category_id,"data-id":auctionItem.id}},[_c('a',{staticClass:"banner-image",attrs:{"href":'/auction/mobile-app/auction-item/' + auctionItem.id}},[(
                                auctionItem.itemImages &&
                                auctionItem.itemImages.file_path
                                    ? auctionItem.itemImages.file_path.includes('.mov') ||
                                      auctionItem.itemImages.file_path.includes('.mp4')
                                    : false
                            )?_c('img',{staticClass:"c-lazy",attrs:{"src":"data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==","data-src":auctionItem.itemImages &&
                                auctionItem.itemImages.cdn_id
                                    ? _vm.cloudinary_video_helper + auctionItem.itemImages.cdn_id + '.jpg'
                                    : '',"alt":auctionItem.item_title}}):_c('img',{staticClass:"c-lazy",attrs:{"src":"data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==","data-src":auctionItem.itemImages &&
                                auctionItem.itemImages.file_path
                                    ? _vm.resizeImg(auctionItem.itemImages.file_path)
                                    : '',"alt":auctionItem.item_title}}),_vm._v(" "),_c('div',{staticClass:"time-remaining-wrapper"},[_vm._m(0,true),_vm._v(" "),_c('div',{staticClass:"time-remaining"},[_vm._v("\n                                "+_vm._s(auctionItem.timeRemaining)+"\n                            ")])])]),_vm._v(" "),_c('div',{staticClass:"item-title-row"},[_c('div',{staticClass:"item-title"},[_vm._v(_vm._s(auctionItem.item_title))]),_vm._v(" "),_c('div',{staticClass:"item-current-bid"},[_vm._v("$"+_vm._s(auctionItem.displayPrice))])]),_vm._v(" "),_c('div',{staticClass:"bid-status-row"},[(auctionItem.userBidStatus === 'NO_WINNER')?_c('div',{staticClass:"my-bid-status"},[_vm._v("\n                            This auction has ended\n                        ")]):(auctionItem.userBidStatus === 'WINNING')?_c('div',{staticClass:"my-bid-status"},[_vm._v("\n                            You’re Winning!\n                        ")]):(auctionItem.userBidStatus === 'WON')?_c('div',{staticClass:"my-bid-status"},[_vm._v("\n                            You’ve Won!\n                        ")]):(auctionItem.userBidStatus === 'OUTBID')?_c('div',{staticClass:"my-bid-status"},[_vm._v("\n                            You’ve been outbid\n                        ")]):(auctionItem.userBidStatus === 'LOST')?_c('div',{staticClass:"my-bid-status"},[_vm._v("\n                            You did not win\n                        ")]):(auctionItem.userBidStatus === 'SOLD')?_c('div',{staticClass:"my-bid-status"},[_vm._v("\n                            This Item has been sold\n                        ")]):_c('div',{staticClass:"my-bid-status"}),_vm._v(" "),(auctionItem.isBidItem)?_c('div',{staticClass:"bid-count",style:({
                                color: _vm.org_skin ? _vm.org_skin.primary_hex : '',
                            })},[_vm._v("\n                            Bids: "+_vm._s(auctionItem.bids.length)+"\n                        ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"bid-now-wrapper"},[_c('a',{staticClass:"btn btn-blue bid-now dynamic-button",style:({
                                'border-color': _vm.org_skin ? _vm.org_skin.primary_hex : '',
                                'background-color': _vm.org_skin ? _vm.org_skin.primary_hex : '',
                            }),attrs:{"href":'/auction/mobile-app/auction-item/' + auctionItem.id}},[_vm._v("\n                            "+_vm._s(auctionItem.buttonText)+"\n                        ")])])])])}),0):_c('div',{staticClass:"no-auction-items"},[_vm._v("This Auction has no items")])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"time-icon"},[_c('i',{staticClass:"fa fa-clock-o"}),_vm._v("\n                                Time Left\n                            ")])}]

export { render, staticRenderFns }