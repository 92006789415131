import Swup from 'swup';
import swal from 'sweetalert';
import moment from 'moment-timezone';
import PinchZoom from 'pinch-zoom-js';
import Cleave from 'cleave.js';
import cLazy from '../../modules/cloudinary-lazy-load';
import pwiSentry from '../../modules/pwi-sentry';

window.Vue = require('vue');

const swup = new Swup({
    cache: false,
    preload: false,
});
const clazy = new cLazy();
const AUCTION_ITEM_SELECTOR = '.auction-item';

let BID_AMOUNT_INPUT;
let CALCULATE_MY_ITEMS_TIME_REMAINING_INTERVAL;

swup.on('pageView', function() {
    swupPageLoad();
});

window.bidAmountIsValid = function(bidAmount, minBid) {
    return !bidAmount || bidAmount < minBid;
};

window.buildCategoryFilterSelector = function() {
    let searchFiltersJSON = getSearchFiltersJSON();
    let categoriesSelector = '';

    if (searchFiltersJSON && searchFiltersJSON.categories && searchFiltersJSON.categories.length) {
        for (let i = 0; i < searchFiltersJSON.categories.length; i++) {
            if (i !== 0) {
                categoriesSelector += ',';
            }
            categoriesSelector += AUCTION_ITEM_SELECTOR + '[data-category-id=' + searchFiltersJSON.categories[i] + ']';
        }
    } else {
        categoriesSelector = AUCTION_ITEM_SELECTOR;
    }
    return categoriesSelector;
};

window.buildNoBidsSelector = function() {
    let searchFiltersJSON = getSearchFiltersJSON();

    if (searchFiltersJSON && searchFiltersJSON.noBids) {
        return AUCTION_ITEM_SELECTOR + '[data-has-bids="0"]';
    }

    return AUCTION_ITEM_SELECTOR;
};

window.buildPriceSelector = function() {
    let searchFiltersJSON = getSearchFiltersJSON();
    if (searchFiltersJSON) {
        let minPrice = parseFloat(searchFiltersJSON.minPrice || 0.0);
        let maxPrice = parseFloat(searchFiltersJSON.maxPrice || 999999999);
        let priceSelector = AUCTION_ITEM_SELECTOR;

        $(AUCTION_ITEM_SELECTOR).each((index, element) => {
            // Show the element if it fits in the price parameters
            let price = parseFloat($(element).attr('data-price'));
            let itemId = $(element).attr('data-id');
            if (price < minPrice || price > maxPrice) {
                priceSelector += ':not([data-id=' + itemId + '])';
            }
        });

        return priceSelector;
    }
};

window.convertUtcTimeToLocal = function(timestamp) {
    timestamp = timestamp.trim();
    if (!timestamp) {
        return;
    }

    let raffleEnd = moment.utc(timestamp).format();
    return new Date(raffleEnd).toLocaleString();
};

window.formatWithLeadingZero = function(string) {
    string = string.toString();
    if (string.length === 1) {
        string = '0' + string;
    }

    return string;
};

window.getAuctionId = function() {
    return $('input#auction-id').val();
};

window.getCsrfToken = function() {
    return $('meta[name="csrf-token"]').attr('content');
};

window.getCardInformationURL = function() {
    return $('input#card-information-url').val();
};

window.getAuctionHomeURL = function() {
  return $('input#auction-home-url').val();
};

window.getEnteredRaffleURL = function() {
    return $('input#entered-raffle-url').val();
};

window.getEnterRaffleURL = function() {
    return $('input#enter-raffle-url').val();
};

window.getRouteName = function() {
    return $('input#route-name').val();
};

window.getSearchFiltersJSON = function() {
    let $searchFilters = $('input#search-filters').val();
    if ($searchFilters) {
        return JSON.parse($searchFilters);
    }
};

window.getSendItemURL = function() {
    return $('input#send-item-url').val();
};

window.getTimeRemainingAsString = function(timestamp) {
    let now = moment.utc();
    let end = moment.utc(timestamp).add(now.utcOffset(), 'minutes');
    let duration = moment.duration(end.diff(now));
    let months = duration.months();
    let days = duration.days();
    let hours = duration.hours() > 0 ? duration.hours() : '00';
    let minutes = duration.minutes() > 0 ? duration.minutes() : '00';
    let seconds = duration.seconds() > 0 ? duration.seconds() : '00';

    if (months < 1) {
        if (days === 1) {
            return 'Over ' + days + ' day remaining';
        } else if (days > 1) {
            return 'Over ' + days + ' days remaining';
        }
    } else if (months === 1) {
        return 'Over ' + months + ' month remaining';
    } else {
        return 'Over ' + months + ' months remaining';
    }

    return (
        formatWithLeadingZero(hours) + ' : ' + formatWithLeadingZero(minutes) + ' : ' + formatWithLeadingZero(seconds)
    );
};

window.getWatchItemURL = function() {
    return $('input#watch-item-url').val();
};

window.getWinningNotificationURL = function() {
    return $('input#winning-notification-url').val();
};

window.initializeBidAmountInput = function() {
    if (shouldInitializeBidAmountInput()) {
        try {
            BID_AMOUNT_INPUT.destroy();
        } catch (exception) {
            // This is fine. It just means that the input element has not been initialized yet.
        }

        let bidAmount = parseFloat($('input#bid-amount').val() || 0).toFixed(2);
        BID_AMOUNT_INPUT = new Cleave('input#bid-amount', {
            numeral: true,
            prefix: '$',
            rawValueTrimPrefix: true, // Note: Removing this will break the +/- buttons
        });
    }
};

window.initializeMyItemsTimeRemainingTimers = function() {
    if (shouldCalculateMyItemsTimeRemaining()) {
        CALCULATE_MY_ITEMS_TIME_REMAINING_INTERVAL = setInterval(updateMyItemsTimeRemaining, 1000);
    } else {
        window.clearInterval(CALCULATE_MY_ITEMS_TIME_REMAINING_INTERVAL);
    }
};

window.initializeUtcLabelToLocal = function() {
    let $raffleLabels = $('.convert-utc-to-local');
    $raffleLabels.each((index, element) => {
        let formattedRaffleEnd = convertUtcTimeToLocal($(element).html());
        $(element).html(formattedRaffleEnd);
    });
};

window.initializeStripeForm = function() {
    if (shouldInitializeStripeForm()) {
        // Create a Stripe client.
        let stripePublishableApiKey = document.getElementById('stripePublishableApiKey').value;
        let stripe = Stripe(stripePublishableApiKey);

        // Create an instance of Elements.
        let elements = stripe.elements();

        // Custom styling can be passed to options when creating an Element.
        // (Note that this demo uses a wider set of styles than the guide below.)
        let style = {
            base: {
                color: '#32325d',
                lineHeight: '18px',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a',
            },
        };

        // Create an instance of the card Element.
        let card = elements.create('card', { style: style });

        // Add an instance of the card Element into the `card-element` <div>.
        card.mount('#card-element');

        // Handle real-time validation errors from the card Element.
        card.addEventListener('change', function(event) {
            let displayError = document.getElementById('card-errors');
            if (event.error) {
                displayError.textContent = event.error.message;
            } else {
                displayError.textContent = '';
            }
        });

        // Handle form submission.
        let submitButton = document.querySelector('.do-pre-billing-submit-action');
        submitButton.addEventListener('click', function(event) {
            event.preventDefault();
            event.stopPropagation();

            stripe
                .createToken(card, {
                    name:
                        $('input[name=paymentMethodFirstName]').val() +
                        ' ' +
                        $('input[name=paymentMethodLastName]').val(),
                    address_line1: $('input[name=billingDetailsAddress1]').val(),
                    address_line2: $('input[name=billingDetailsAddress2]').val(),
                    address_city: $('input[name=billingDetailsCity]').val(),
                    address_state: $('select[name=billingDetailsState] option:selected').attr('data-state-code'),
                    address_zip: $('input[name=billingDetailsZip]').val(),
                    address_country: $('input[name=billingDetailsCountry]').val(),
                })
                .then(function(result) {
                    if (result.error) {
                        // Inform the user if there was an error.
                        let errorElement = document.getElementById('card-errors');
                        errorElement.textContent = result.error.message;
                    } else {
                        // Send the token to your server.
                        ////console.log("Stripe token: ", result.token);
                        $('input[name=stripeToken]').val(result.token.id);
                        $('input[name=stripeCardLast4]').val(result.token.card.last4);
                        $('input[name=stripeCard]').val(JSON.stringify(result.token.card));

                        // FIXME: Workaround for Auction app
                        $('#card-information-form-submit').trigger('click');
                    }
                });
        });
    }
};

window.initializeZoomableImageModal = function() {
    let pz = new PinchZoom(document.querySelector('#image-modal-image'), {
        animationDuration: 150,
        zoomOutFactor: 1,
    });
};

window.intersectArrays = function(array1, array2) {
    return array1.filter(value => -1 !== array2.indexOf(value));
};

window.lazyLoadCarouselCloudinaryImages = function() {
    let firstCarouselImage = $('.carousel-inner .item.active img.carousel-image');
    let width = firstCarouselImage.width();
    let height = firstCarouselImage.height();

    $('.carousel-inner .item:not(.active) img.carousel-image').each(function(index, element) {
        clazy.loadImage(element, {
            width: width,
            height: height,
            qualityMultiplier: 2,
        });
    });
};

window.parseErrorMessages = function(errors, lineBreak) {
    let message = '';
    if (lineBreak === undefined) {
        lineBreak = '\n';
    }

    // If the message argument is an array, then parse them into a <ul>.
    if (Array.isArray(errors)) {
        for (let index in errors) {
            message += '' + errors[index] + lineBreak;
        }
    }
    // The message argument is a string so just use it as-is.
    else if (typeof errors === 'string') {
        message += errors;
    }
    // Only strings and arrays will be accounted for. Use default error message otherwise.
    else {
        message = 'There was a problem submitting your request.';
    }
    return message;
};

window.setSearchFiltersJSON = function(searchFiltersJSON) {
    if (typeof searchFiltersJSON === 'object') {
        searchFiltersJSON = JSON.stringify(searchFiltersJSON);
    }
    // check if user is admin or not and then store filter data to localstorage
    if ($('#is-admin-flag').length && $('#auction_id').val() != '') {
        let auctionFilterData = {};
        let auctionId = $('#auction_id').val();
        if (localStorage.getItem('auctionFilter') != null) {
            auctionFilterData = JSON.parse(localStorage.getItem('auctionFilter'));
        }
        auctionFilterData[auctionId] = searchFiltersJSON;
        auctionFilterData = JSON.stringify(auctionFilterData);
        localStorage.setItem('auctionFilter', auctionFilterData);
    }

    $('input#search-filters').val(searchFiltersJSON);
    $('input#search-filters').trigger('change');
};
// restore filter data from localstorage
window.restoreFilterData = function() {
    if (localStorage.getItem('auctionFilter') != null) {
        let auctionfilterData = localStorage.getItem('auctionFilter');
        let auctionFilterObj = JSON.parse(auctionfilterData);
        if ($('#auction_id').val() != '') {
            if (auctionFilterObj[$('#auction_id').val()]) {
                let filterObj = JSON.parse(auctionFilterObj[$('#auction_id').val()]);
                if (filterObj) {
                    if (filterObj.hasOwnProperty('categories')) {
                        filterObj.categories.forEach(element => {
                            $("input[data-category-id='" + element + "']").prop('checked', true);
                        });
                    }

                    if (filterObj.hasOwnProperty('maxPrice')) {
                        $('#search-maximum-price').val(filterObj.maxPrice);
                    }

                    if (filterObj.hasOwnProperty('minPrice')) {
                        $('#search-minimum-price').val(filterObj.minPrice);
                    }

                    if (filterObj.hasOwnProperty('noBids')) {
                        $('#no-bid').prop('checked', true);
                    }

                    $('input#search-filters').val(JSON.stringify(filterObj));
                    $('input#search-filters').trigger('change');
                    updateVisibleAuctionItems();
                }
            }
        }

        // $('input#search-filters').trigger('change');
        // updateVisibleAuctionItems();
    }
};
// event listener for changing auction item groups

// $(".auction-item-groups").on("DOMSubtreeModified", function() {
//   debugger
//   restoreFilterData();
// });

// var auctionChecktarget = document.querySelector('#vue-item-list-wrapper');

// var observer = new MutationObserver(function(mutations) {
//     console.log('helo');
// });

// observer.observe(auctionChecktarget, {
//     attributes:    true,
//     childList:     true,
//     characterData: true
// });
// setTimeout("console.log('Hello World!');", 500);

window.shouldCalculateMyItemsTimeRemaining = function() {
    return getRouteName() === 'auction.mobile-app.my-items';
};

window.shouldInitializeBidAmountInput = function() {
    return $('input#bid-amount').length;
};

window.shouldInitializeStripeForm = function() {
    return $('.stripe-cc-form').length > 0;
};

window.showCardErrorModal = function(errors) {
    let $modal = $('.modal#card-error-modal');
    let $errorMessage = $modal.find('.error-message');
    let message = '';

    if (errors) {
        message = parseErrorMessages(errors, '<br>');
    }

    $errorMessage.html(message);

    $modal.modal('show');
};

window.showCardInformationModal = function() {
    $('.modal#card-information-modal').modal('show');
};

window.showErrorMessage = function(errors, callback) {
    // Set error message text
    let message = 'There was a problem submitting your request:\n\n';
    if (errors) {
        message = parseErrorMessages(errors);
    }
    // No error message arguments provided. Use the default error message.
    else {
        message = 'There was a problem submitting your request.';
    }

    // Show the error message
    swal({
        icon: 'error',
        title: 'Oops!',
        text: message,
        button: {
            text: 'OK',
            value: true,
            visible: true,
            className: 'btn btn-danger large',
            closeModal: true,
        },
    }).then(() => {
        if (callback) {
            callback();
        }
    });
};

window.showSuccessMessage = function(params) {
    params = {
        callback: params.callback || function() {},
        icon: params.icon || null,
        message: params.message,
        title: params.title || 'Success!',
    };
    swal({
        button: {
            text: 'OK',
            value: true,
            visible: true,
            className: 'btn btn-primary btn-block large ' + ('btn-' + params.icon || ''),
            closeModal: true,
        },
        className: 'full-width-buttons',
        icon: params.icon,
        text: params.message,
        title: params.title,
    }).then(() => {
        params.callback();
    });
};

window.showAllPreviousBids = function() {
    $('.previous-bids.truncated').removeClass('truncated');
    $('.previous-bid.hidden').removeClass('hidden');
    $('button#show-fewer-previous-bids').removeClass('hidden');
    $('button#show-all-previous-bids').addClass('hidden');
};

window.showFewerPreviousBids = function() {
    $('.previous-bids:not(.truncated)').addClass('truncated');
    $('.previous-bid.hideable:not(.hidden)').addClass('hidden');
    $('button#show-all-previous-bids').removeClass('hidden');
    $('button#show-fewer-previous-bids').addClass('hidden');
};

window.initializeVueComponents = function() {
    if ($('#vue-item-list-wrapper')) {
        Vue.component(
            'item-list-component',
            require('../../components/auction/mobile-app/ItemListComponent.vue').default
        );
        new Vue({
            el: '#vue-item-list-wrapper',
        });
    }
    if ($('#vue-item-bid-component-wrapper')) {
        Vue.component(
            'item-bid-component',
            require('../../components/auction/mobile-app/ItemBidComponent.vue').default
        );
        new Vue({
            el: '#vue-item-bid-component-wrapper',
        });
    }
    if ($('#vue-raffle-purchase-wrapper')) {
        Vue.component(
            'raffle-purchase-component',
            require('../../components/auction/mobile-app/RafflePurchaseComponent.vue').default
        );
        new Vue({
            el: '#vue-raffle-purchase-wrapper',
        });
    }
    if ($('#vue-raffle-list-wrapper')) {
        Vue.component(
            'raffle-list-component',
            require('../../components/auction/mobile-app/RaffleListComponent.vue').default
        );
        new Vue({
            el: '#vue-raffle-list-wrapper',
        });
    }
};

window.swupPageLoad = function() {
    // Load the Cloudinary images
    clazy.loadAllImages({
        qualityMultiplier: 2,
    });

    // Lazy load carousel Cloudinary images
    lazyLoadCarouselCloudinaryImages();

    // Item "time remaining" timers on the "My Items" page
    initializeMyItemsTimeRemainingTimers();

    // Raffle end time on the raffle item page
    initializeUtcLabelToLocal();

    // Initialize the Stripe form
    initializeStripeForm();

    // Initialize Vue components
    initializeVueComponents();

    pwiSentry.init();
};

window.toggleAsyncButton = function($button) {
    if ($button.attr('disabled')) {
        // Restore original html before loading animation was set
        let oldHtml = $button.attr('data-old-html');
        $button.html(oldHtml);

        // Re-enable the button
        $button.removeAttr('disabled');
        $button.removeClass('disabled');
    } else {
        // Disable the button
        $button.attr('disabled', 'disabled');
        $button.addClass('disabled');

        // Change the button to the loading animation
        let oldHtml = $button.html();
        $button.attr('data-old-html', oldHtml);
        $button.html('<i class="fa fa-cog fa-spin" aria-hidden="true"></i>');
    }
};

window.toggleSearchFilterPane = function(filter) {
    if (filter) {
        // Collapse the other filter panes
        $('.filter-pane:not(.' + filter + ')').addClass('collapsed');

        // Toggle this pane
        $('.filter-pane.' + filter).toggleClass('collapsed');
    } else {
        $('.filter-pane').addClass('collapsed');
    }
};

window.toggleWatchButton = function($button) {
    let html = $button
        .html()
        .toLowerCase()
        .trim();
    if ($button.hasClass('watching')) {
        $button.removeClass('watching');
    } else {
        $button.addClass('watching');
    }
    if (html === 'watch') {
        $button.html('Unwatch');
    } else if (html === 'unwatch') {
        $button.html('Watch');
    }
};

window.updateVisibleAuctionItems = function() {
    // Build categories selector
    let categoriesSelector = buildCategoryFilterSelector();

    // Build price selector
    let priceSelector = buildPriceSelector();

    // Build no bids selector
    let noBidsSelector = buildNoBidsSelector();

    // Compile array of Auction items to show the users
    let itemsToShow = intersectArrays($(categoriesSelector).toArray(), $(priceSelector).toArray());
    itemsToShow = intersectArrays($(itemsToShow).toArray(), $(noBidsSelector).toArray());

    // Display the appropriate items
    $('.auction-item-groups .auction-item').hide();
    for (let i = 0; i < itemsToShow.length; i++) {
        $(itemsToShow[i]).show();
    }
};

window.updateMyItemsTimeRemaining = function() {
    let $items = $('.auction-app-page.my-items .my-item-list .my-item:not(.ended) .time-remaining');

    $items.each(function(key, element) {
        let enddate = $(element).data('enddate');
        let timeRemainingString = getTimeRemainingAsString(enddate);

        // Update the timer
        $(element).html(timeRemainingString);

        // Reload the page when an auction/raffle comes to an end
        if (timeRemainingString === '00 : 00 : 00') {
            // location.reload();
        }
    });
};

window.userHasCardOnFile = function() {
    return !!$('input#user-has-card-on-file').val();
};

// User places a bid on an item
window.placeAuctionItemBid = function(buttonSelector, callback) {
    initializeBidAmountInput();

    let $button = $(buttonSelector);
    let endpoint = $button.data('endpoint');
    let itemId = $('input#item-id').val();
    let $bidInput = $('input#bid-amount');
    let minBid = parseFloat($bidInput.attr('min'));
    let bidAmount = parseFloat(BID_AMOUNT_INPUT.getRawValue()) || 0.0;

    if (!userHasCardOnFile()) {
        showCardInformationModal();
        callback();
        return false;
    }

    if (bidAmountIsValid(bidAmount, minBid)) {
        showErrorMessage('Your bid must be at least $' + minBid.toFixed(2).toString());
        callback();
        return false;
    }

    $.ajax({
        url: endpoint,
        method: 'POST',
        data: {
            _token: getCsrfToken(),
            bidAmount: bidAmount,
            itemId: itemId,
        },
    })
        .done(function(response) {
            ////console.log(response);

            if (response.success) {
                showSuccessMessage({
                    message: 'CONGRATS! Your bid has been placed, and you are currently winning!',
                    callback: () => {
                        location.reload();
                    },
                });
            } else {
                showErrorMessage(response.errors, () => {
                    location.reload();
                });
            }
        })
        .fail(function(jqXHR, textStatus, errorThrown) {
            ////console.log('jqXHR: ', jqXHR);
            ////console.log('textStatus: ', textStatus);
            ////console.log('errorThrown: ', errorThrown);
            showErrorMessage(textStatus, () => {
                location.reload();
            });
        })
        .always(function() {
            callback();
        });
};

// User purchases a 'buy now' item
window.purchaseBuyNowItem = function(buttonSelector, callback) {
    let $button = $(buttonSelector);
    let endpoint = $button.data('endpoint');
    let itemId = $('input#item-id').val();

    if (!userHasCardOnFile()) {
        showCardInformationModal();
        callback();
        return false;
    }

    $.ajax({
        url: endpoint,
        method: 'POST',
        data: {
            _token: getCsrfToken(),
        },
    })
        .done(function(response) {
            ////console.log(response);

            if (response.success) {
                showSuccessMessage({
                    message: 'Your purchase is complete!',
                    callback: () => {
                        location.reload();
                    },
                });
            } else {
                showErrorMessage(response.errors);
            }
        })
        .fail(function(jqXHR, textStatus, errorThrown) {
            ////console.log('jqXHR: ', jqXHR);
            ////console.log('textStatus: ', textStatus);
            ////console.log('errorThrown: ', errorThrown);

            showErrorMessage(textStatus);
        })
        .always(function() {
            callback();
        });
};

// User purchases a raffle item
window.purchaseRaffleTicket = function(buttonSelector, callback) {
    if (!userHasCardOnFile()) {
        showCardInformationModal();
        callback();
        return false;
    }

    let $button = $(buttonSelector);
    let raffleId = $('input#raffle-id').val();
    let ticketQty = $('input#raffle-ticket-quantity').val();

    $.ajax({
        url: getEnterRaffleURL(),
        method: 'POST',
        data: {
            _token: getCsrfToken(),
            raffleId: raffleId,
            ticketQty: ticketQty,
        },
    })
        .done(function(response) {
            if (response.success) {
                showSuccessMessage({
                    message: 'Your raffle tickets have been purchased',
                    callback: () => {
                        // location.href = getEnteredRaffleURL();
                    },
                });
            } else {
                showErrorMessage(response.errors);
            }
        })
        .fail(function(jqXHR, textStatus, errorThrown) {
            ////console.log('jqXHR: ', jqXHR);
            ////console.log('textStatus: ', textStatus);
            ////console.log('errorThrown: ', errorThrown);
            showErrorMessage(textStatus);
        })
        .always(function() {
            callback();
        });
};

// Show the 'send item' modal
window.showSendItemModal = function() {
    $('.modal#send-item-modal').modal('show');
};

// User sends an item link
window.sendAuctionItemLink = function(callback) {
    let $modal = $('.modal#send-item-modal');
    let email = $('input#send-email-input').val();

    if (!email) {
        showErrorMessage('Email is required');
        callback();
        return false;
    }

    $.ajax({
        url: getSendItemURL(),
        method: 'POST',
        data: {
            _token: getCsrfToken(),
            auctionId: getAuctionId(),
            email: email,
            url: location.href,
        },
    })
        .done(function(response) {
            ////console.log('response: ', response);

            if (response.success) {
                showSuccessMessage({
                    message: 'Your message has been sent.',
                });
            } else {
                showErrorMessage(response.errors);
            }
        })
        .fail(function(jqXHR, textStatus, errorThrown) {
            ////console.log('jqXHR: ', jqXHR);
            ////console.log('textStatus: ', textStatus);
            ////console.log('errorThrown: ', errorThrown);

            showErrorMessage(textStatus);
        })
        .always(function() {
            callback();
            $modal.modal('hide');
        });
};

$(document).ready(function() {
    swupPageLoad();
    restoreFilterData();
    defaultFillter();
    // User clicks the "back" button
    $(document).on('click', '.back-button', () => {
        window.history.back();
    });

    // User submits the card information form
    $(document).on('click', 'button#card-information-form-submit', event => {
        event.preventDefault();
        event.stopPropagation();

        let $button = $(event.currentTarget);
        let form = document.querySelector('form#card-information-form');
        let URL = $(form).attr('action');
        let formValues = new FormData(form);

        // Set a submit variable to true
        // This will be used to check whether or not we should send the request
        let submit = true;
        // If userFirstName or userLastName are present
        // This means the user does not have a name in their user record
        // Let's set submit to false so we don't automatically submit
        // So we can show front end validation
        if ($('#userFirstName').length) {
            if ($('#userFirstName').val() == '') {
                submit = false;
            }
        }
        if ($('#userLastName').length) {
            if ($('#userLastName').val() == '') {
                submit = false;
            }
        }

        if (submit) {
            toggleAsyncButton($button);

            $.ajax({
                url: URL,
                data: formValues,
                processData: false,
                contentType: false,
                type: 'POST',
                dataType: 'json',
            })
                .done(function(response) {
                    ////console.log('results: ', response);
                    if (response.success) {
                        showSuccessMessage({
                            message: 'Your card has been saved.',
                            callback: () => {
                                if (response.redirect) {
                                    location.href = response.redirect;
                                } else {
                                    location.href = getAuctionHomeURL();
                                }
                            },
                        });
                    } else {
                        showCardErrorModal(response.errors);
                    }
                })
                .fail(function(jqXHR, textStatus, errorThrown) {
                    ////console.log('failed.');
                    ////console.log(jqXHR);
                    ////console.log(textStatus);
                    ////console.log(errorThrown);
                    showCardErrorModal(textStatus);
                })
                .always(function() {
                    toggleAsyncButton($button);
                });

            return false;
        } else {
            // If submit is false, let's add error borders to the user account name fields
            // So the user knows there is an issue
            if ($('#userFirstName').val() == '') {
                $('#userFirstName').addClass('error-border');
            } else {
                $('#userFirstName').removeClass('error-border');
            }

            if ($('#userLastName').val() == '') {
                $('#userLastName').addClass('error-border');
            } else {
                $('#userLastName').removeClass('error-border');
            }
        }
    });

    // User watches an item/raffle
    $(document).on('click', '.watch-item-button', event => {
        event.stopPropagation();

        let $button = $(event.currentTarget);
        let watchType = $button.attr('data-watch-type');
        let watchId = $button.attr('data-watch-id');

        toggleWatchButton($button);

        $.ajax({
            url: getWatchItemURL(),
            method: 'POST',
            data: {
                _token: getCsrfToken(),
                id: watchId,
                type: watchType,
            },
        })
            .done(function(response) {
                ////console.log('success: ', response);
            })
            .fail(function(jqXHR, textStatus, errorThrown) {
                ////console.log('failure: ', textStatus);
                toggleWatchButton($button);
            });
    });

    // User toggles previous bids list
    $(document).on('click', '.previous-bids.expandable', event => {
        let $target = $(event.currentTarget);

        if ($target.hasClass('truncated')) {
            showAllPreviousBids();
        } else {
            showFewerPreviousBids();
        }
    });

    // User clicks 'show all' previous bids
    $(document).on('click', '#show-all-previous-bids', showAllPreviousBids);

    // User clicks 'show fewer' previous bids
    $(document).on('click', '#show-fewer-previous-bids', showFewerPreviousBids);

    // User clicks on carousel image
    $(document).on('click', '.zoomable', event => {
        let image = event.currentTarget;
        let modalBody = document.querySelector('.modal#image-modal .modal-body');
        if (image.dataset.src.includes('/video/')) {
            let zoomableVideo = document.createElement('video');
            zoomableVideo.src = image.dataset.src + '.mp4';
            zoomableVideo.controls = true;
            zoomableVideo.id = 'image-modal-image';
            modalBody.innerHTML = '';
            modalBody.append(zoomableVideo);
        } else {
            let zoomableImage = document.createElement('img');
            zoomableImage.src = image.dataset.src;
            zoomableImage.id = 'image-modal-image';
            modalBody.innerHTML = '';
            modalBody.append(zoomableImage);
        }

        $('.modal#image-modal').modal('show');

        setTimeout(() => {
            $('.modal#image-modal').modal('adjustDialog');
        }, 500);
    });

    // Set up the zoomable image when the modal loads
    $(document).on('shown.bs.modal', '.modal#image-modal', () => {
        initializeZoomableImageModal();
    });

    // User toggles a search filter pane
    $(document).on('click', '.toggle-search-pane', event => {
        let $button = $(event.currentTarget);
        let filter = $button.data('filter');

        toggleSearchFilterPane(filter);
    });

    // User clicks an item category checkbox
    $(document).on('click', 'button#search-categories-apply-button', () => {
        let checkedCount = $('input.search-category-checkbox:checked').length;

        // Get the search filters JSON object
        let searchFiltersJSON = getSearchFiltersJSON();

        // No checkboxes checked so delete all category IDs
        if (!checkedCount && searchFiltersJSON.categories) {
            delete searchFiltersJSON.categories;
        }

        // Show include applicable category IDs
        else {
            searchFiltersJSON.categories = [];

            $('input.search-category-checkbox:checked').each((index, element) => {
                let categoryId = parseInt($(element).attr('data-category-id') || 0);

                searchFiltersJSON.categories.push(categoryId);
            });
        }

        // Save the updated search filters
        setSearchFiltersJSON(searchFiltersJSON);

        // Hide the search filters
        toggleSearchFilterPane();
    });

    // User clicks an item category checkbox
    $(document).on('click', 'button#search-price-apply-button', () => {
        let minPrice = $('input#search-minimum-price').val();
        let maxPrice = $('input#search-maximum-price').val();

        // Update the search filters JSON object
        let searchFiltersJSON = getSearchFiltersJSON();

        // Set min price
        if (minPrice) {
            searchFiltersJSON.minPrice = parseFloat(minPrice);
        } else {
            if (searchFiltersJSON.minPrice !== undefined) {
                delete searchFiltersJSON.minPrice;
            }
        }

        // Set max price
        if (maxPrice) {
            searchFiltersJSON.maxPrice = parseFloat(maxPrice);
        } else {
            if (searchFiltersJSON.maxPrice !== undefined) {
                delete searchFiltersJSON.maxPrice;
            }
        }

        // Save the updated search filters
        setSearchFiltersJSON(searchFiltersJSON);

        // Hide the search filters
        toggleSearchFilterPane();
    });

    // User clicks the "no bid" checkbox
    $(document).on('change', 'input#no-bid', event => {
        // Update the search filters JSON object
        let searchFiltersJSON = getSearchFiltersJSON();

        // Set the value
        if (event.currentTarget.checked) {
            searchFiltersJSON.noBids = true;
        } else {
            if (searchFiltersJSON.noBids !== undefined) {
                delete searchFiltersJSON.noBids;
            }
        }

        // Save the updated search filters
        setSearchFiltersJSON(searchFiltersJSON);

        // Hide the search filters
        toggleSearchFilterPane();
    });

    // User cancels the search filter
    $(document).on('click', 'button#search-categories-cancel-button, button#search-price-cancel-button', () => {
        toggleSearchFilterPane();
    });

    // Search filters updated
    $(document).on('change', 'input#search-filters', event => {
        updateVisibleAuctionItems();
    });

    // Update SMS Notification Preferences
    $(document).on('submit', 'form#set-sms-notification-form', event => {
        event.preventDefault();

        let $form = $(event.currentTarget);
        let endpoint = $form.attr('action');
        let method = $form.attr('method');
        let $button = $form.find('button[type=submit]');
        let formValues = new FormData(event.target);
        formValues.append('_token', getCsrfToken());

        $button.attr('disabled');

        $.ajax({
            url: endpoint,
            data: formValues,
            method: method,
            processData: false,
            contentType: false,
            dataType: 'json',
        })
            .done(response => {
                if (response.success) {
                    showSuccessMessage({
                        message: 'Your SMS notification settings have been saved',
                        icon: 'success',
                    });
                } else {
                    showErrorMessage(response.errors);
                }
            })
            .always(() => {
                $button.removeAttr('disabled');
            });
    });

    document.addEventListener('deviceready', onDeviceReady, false);

    function onDeviceReady() {
        // Mock device.platform property if not available
        if (!window.device) {
            window.device = { platform: 'Browser' };
        }

        handleExternalURLs();
    }

    function handleExternalURLs() {
        // Handle click events for all external URLs
        if (device.platform.toUpperCase() === 'ANDROID') {
            $(document).on('click', 'a[data-open-in-browser][href^="http"]', function(e) {
                alert('opening Android link');
                let url = $(this).attr('href');
                navigator.app.loadUrl(url, { openExternal: true });
                e.preventDefault();
            });
        } else if (device.platform.toUpperCase() === 'IOS') {
            $(document).on('click', 'a[data-open-in-browser][href^="http"]', function(e) {
                alert('opening iOS link');
                let url = $(this).attr('href');
                window.open(url, '_system');
                e.preventDefault();
            });
        } else {
            // Leave standard behaviour
        }
    }
});
// get auctionitems with filter data restored from localstorage.
// if filter is used, this function should be stopped.
window.defaultFillter = function() {
    let filterInput = $('input#search-filters').val();
    if(filterInput) {
      setInterval(updateVisibleAuctionItems, 1000);
    }
};
setTimeout(() => {
    clearInterval(defaultFillter);
}, 5000);
