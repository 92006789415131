const IMG_SELECTOR = '.c-lazy';
const IMG_LOADED_CLASS = 'c-loaded';

export default class cLazy {

    /**
     * Loads the lazy-loaded Cloudinary images.
     */
    loadAllImages(params) {
        $(IMG_SELECTOR).each((index, element) => {
            this.loadImage(element, params);
        });
    }

    /**
     * Gets the image URL and loads the image to the page.
     *
     * @param element
     * @param params
     */
    loadImage(element, params) {
        let url    = $(element).attr('data-src');
        let width  = $(element).width();
        let height = $(element).height();

        // Optional: override values
        if (params) {
            if (params.url) {
                url = params.url;
            }
            if (params.width) {
                width = params.width;
            }
            if (params.height) {
                height = params.height;
            }
            if (params.qualityMultiplier) {
                width *= params.qualityMultiplier;
                height *= params.qualityMultiplier;
            }
        }

        // Round the image dimensions to avoid 4xx errors because of decimals in the URL
        width  = Math.ceil(width);
        height = Math.ceil(height);

        // Update the URL with proper dimensions
        let urlSizeString = '/image/upload/w_' + width + ',h_' + height + ',c_pad,b_auto,';
        url = url.replace('/image/upload', urlSizeString);

        // Load <img> tags
        if (element.tagName === 'IMG') {
            $(element).attr('src', url);
        }

        // Load <div>, <span>, <a>, etc...
        else {
            let style = $(element).attr('style') || '';
            style += 'background: url("' + url + '") no-repeat center center; background-size: cover;';
            $(element).attr('style', style);
        }

        // Fade transition
        this.fadeIn(element);
    }

    /**
     * Make the image fade in.
     *
     * @param element
     */
    fadeIn(element) {
        if (element.tagName === 'IMG') {
            $(element).attr('onload', 'this.className += " ' + IMG_LOADED_CLASS + '"');
        } else {
            $(element).addClass(IMG_LOADED_CLASS);
        }
    }
}
